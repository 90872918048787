.custom-integration-form {
  max-width: 310px;
  margin: 0 auto;

  &__buttons-block {
    display: flex;

    button {
      &:first-child {
        margin-right: 15px;
      }
    }
  }

  &__copy-button-block {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    min-height: 36px;
  }

  &__checkbox-container {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
    grid-auto-flow: column;
    grid-row-gap: 20px;
    margin-bottom: 30px;
  }
}
