/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.button__wrapper {
  width: 100%;
  max-width: 275px;
  height: 50px;
  margin: 0 auto;
  background-color: #0fb9da;
  font: 600 12px/14px "SFPro";
  color: #fff;
  border: 1px solid transparent;
  transition: 0.3s;
  text-transform: uppercase;
  border-radius: 23px;
  white-space: nowrap; }
  @media (max-width: 1023px), (orientation: portrait) {
    .button__wrapper {
      height: 40px; } }
  .button__wrapper--auto-width {
    width: auto; }
  .button__wrapper--secondary {
    height: 36px;
    padding: 0 15px; }
  .button__wrapper:hover {
    opacity: 0.8; }
  .button__wrapper--full-width {
    max-width: 100%; }
  .button__wrapper--auto-width {
    width: auto;
    padding: 0 25px; }
  .button__wrapper--borderedBlue {
    border: 1px solid #0fb9da;
    background-color: transparent;
    color: #0fb9da; }
    .button__wrapper--borderedBlue:hover {
      border: 1px solid transparent;
      background-color: #0fb9da;
      color: #fff;
      opacity: 1; }
  .button__wrapper--borderedRed {
    border: 1px solid #fa6678;
    background-color: transparent;
    color: #fa6678; }
  .button__wrapper--filledGrey {
    background-color: rgba(144, 153, 170, 0.5);
    color: #fff; }
    .button__wrapper--filledGrey:hover {
      border: 1px solid transparent;
      background-color: rgba(144, 153, 170, 0.5); }
  .button__wrapper--filledRed {
    background-color: #fa6678;
    color: #fff; }
    .button__wrapper--filledRed:hover {
      border: 1px solid transparent;
      background-color: #fa6678; }
  .button__wrapper--grey {
    background-color: rgba(255, 255, 255, 0.2); }
    .button__wrapper--grey:hover {
      border: 1px solid transparent;
      background-color: rgba(255, 255, 255, 0.2); }
  .button__wrapper[disabled] {
    background-color: rgba(144, 153, 170, 0.5);
    color: #fff;
    cursor: default; }
    .button__wrapper[disabled]:hover {
      opacity: 1; }
