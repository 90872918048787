/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.base-tabs-header {
  display: block;
  position: relative; }
  .base-tabs-header:after {
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #9099aa;
    opacity: 0.2;
    display: block;
    content: '';
    z-index: -1; }
  .base-tabs-header__tab-list {
    width: 100%;
    display: flex;
    justify-content: center; }
  .base-tabs-header__tab-title {
    transition: color 0.3s; }
  .base-tabs-header__tab {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: #9099aa;
    border-bottom: 3px solid transparent;
    margin: 0 35px; }
    .base-tabs-header__tab .query-link {
      padding-bottom: 15px; }
    @media (max-width: 1024px) {
      .base-tabs-header__tab {
        margin: 0 11px;
        font-size: 12px;
        flex: 1;
        text-align: center; }
        .base-tabs-header__tab .query-link {
          padding-bottom: 8px; }
        .base-tabs-header__tab:first-child {
          margin-left: 0; }
        .base-tabs-header__tab:last-child {
          margin-right: 0; } }
    .base-tabs-header__tab:hover .base-tabs-header__tab-title {
      color: #0fb9da; }
    .base-tabs-header__tab--active {
      border-color: #0fb9da; }
      .base-tabs-header__tab--active .base-tabs-header__tab-title {
        color: #0fb9da; }
