/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.collapsed-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  color: #262d57; }
  .collapsed-item--with-border {
    border-bottom: 1px solid rgba(144, 153, 170, 0.2); }
  @media (max-width: 1023px) {
    .collapsed-item {
      width: 100%;
      padding: 0; } }
  .collapsed-item__header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 18px 0;
    cursor: pointer; }
    @media (max-width: 1023px) {
      .collapsed-item__header {
        padding: 16px 0; } }
  .collapsed-item__header-title {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    padding-right: 30px;
    font-weight: bold; }
    @media (max-width: 1023px) {
      .collapsed-item__header-title {
        padding-right: 20px; } }
  .collapsed-item__header-number {
    width: 20px;
    margin-right: 20px;
    font-size: 12px;
    color: rgba(144, 153, 170, 0.5); }
    @media (max-width: 1023px) {
      .collapsed-item__header-number {
        margin-right: 10px; } }
  .collapsed-item__collapse-icon-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    height: 22px;
    width: 22px;
    cursor: pointer; }
    @media (max-width: 1023px) {
      .collapsed-item__collapse-icon-wrapper {
        right: 0; } }
  .collapsed-item__collapse-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    font-size: 15px; }
    @media (max-width: 1023px) {
      .collapsed-item__collapse-icon {
        right: 5px; } }
  .collapsed-item__collapse-wrapper {
    width: 100%; }
  .collapsed-item__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
    padding: 16px;
    padding-left: 30px;
    font-size: 13px; }
    @media (max-width: 1023px) {
      .collapsed-item__content {
        padding-left: 18px; } }
