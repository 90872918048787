/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.modal__window-wrapper {
  position: fixed;
  z-index: 1000;
  left: 0px;
  top: 0px;
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  z-index: 2000;
  pointer-events: auto;
  animation: show 0.36s ease both;
  background-color: rgba(144, 153, 170, 0.5); }
  .modal__window-wrapper--delay {
    animation-delay: 0.8s; }

.modal__modal-margin-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 535px;
  margin: auto;
  padding: 10px; }

.modal__modal {
  min-height: 350px;
  max-height: calc(100vh - 20px);
  overflow-y: auto;
  padding: 55px 25px;
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  position: relative;
  animation: popup-content 0.36s ease; }
  .modal__modal--delay {
    animation-delay: 0.8s; }
  .modal__modal--adaptive-height {
    min-height: initial; }

.modal__modal-close-icon {
  position: absolute;
  top: 25px;
  right: 25px;
  transition: opacity 0.3s; }
  .modal__modal-close-icon div {
    width: 25px;
    height: 25px; }
  .modal__modal-close-icon:hover {
    opacity: 0.8;
    cursor: pointer; }

.modal__modal-body {
  flex: 1;
  text-align: center;
  font-size: 20px;
  line-height: 27px;
  color: #9099aa;
  margin-bottom: 30px; }
  .modal__modal-body--small {
    font-size: 15px;
    line-height: 21px; }

.modal__modal-footer--with-cancel {
  display: flex; }

.modal__modal-header {
  margin-bottom: 30px; }

.modal__modal-header-icon {
  max-width: 54px;
  margin: 0 auto;
  margin-bottom: 25px; }
  .modal__modal-header-icon--success svg path {
    fill: #00b2ab; }
  .modal__modal-header-icon--warning svg path {
    fill: #fa6678; }

.modal__close-btn-wrapper {
  position: absolute;
  z-index: 1;
  top: 13px;
  right: 11px;
  display: none;
  pointer-events: none; }
  @media (max-width: 767px) {
    .modal__close-btn-wrapper {
      display: block;
      pointer-events: auto; } }
