/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.select__wrapper {
  position: relative;
  width: 100%; }

.select__select {
  width: 100%;
  height: 40px;
  padding: 4px 40px 4px 16px;
  border: 1px solid rgba(144, 153, 170, 0.2);
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  font-family: "SFPro";
  color: #9099aa;
  overflow-x: hidden;
  appearance: none !important;
  background-color: transparent;
  text-transform: capitalize;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out; }
  .select__select--main-input {
    border: 0;
    border-bottom: 1px solid rgba(144, 153, 170, 0.2);
    border-radius: 0;
    text-transform: uppercase;
    font-size: 15px;
    padding-left: 0; }
  .select__select:hover {
    border-color: rgba(74, 144, 226, 0.4); }
  .select__select:focus {
    border-color: #0fb9da; }
  .select__select--error {
    border-color: #fa6678; }
  .select__select[disabled] ~ .select__side-block div svg path {
    fill: rgba(144, 153, 170, 0.2); }
  .select__select[disabled]:hover, .select__select[disabled]:focus {
    border-color: rgba(144, 153, 170, 0.2); }

.select__side-block {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 39px;
  height: 38px;
  border-radius: 0 3px 3px 0;
  background-color: #fff;
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  pointer-events: none; }
  .select__side-block:hover {
    cursor: pointer; }

.select__icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 10px;
  height: 10px; }
  .select__icon-wrapper div {
    display: flex;
    justify-content: center;
    align-items: center; }
    .select__icon-wrapper div svg path {
      fill: #0fb9da; }
