/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.radio-btn__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 50px; }

.radio-btn__title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  max-width: 100px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  .radio-btn__title--auto-width {
    max-width: 100%; }

.radio-btn__input {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #fff;
  cursor: pointer;
  transition: 0.2s all linear;
  outline: none;
  margin-right: 8px; }
  .radio-btn__input:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #0fb9da;
    opacity: 0;
    transition: 0.2s all linear; }
  .radio-btn__input:hover, .radio-btn__input:checked {
    border: 1px solid #0fb9da; }
    .radio-btn__input:hover:after, .radio-btn__input:checked:after {
      opacity: 1; }
  .radio-btn__input--error {
    border-color: #fa6678; }
    .radio-btn__input--error:checked ~ span {
      color: #fff; }
    .radio-btn__input--error ~ span,
    .radio-btn__input--error ~ span a {
      color: #fa6678; }
