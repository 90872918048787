/**
* Config
*/
/**
* Colors
*/
/**
* Animations
*/
@keyframes popup-content {
  0% {
    transform: translateY(20px); }
  100% {
    transform: translateY(0px); } }

@keyframes show {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeUp {
  0% {
    transform: translateY(30px);
    opacity: 0; }
  100% {
    transform: translateY(0px);
    opacity: 1; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.copy-to-clipboard {
  position: relative; }
  .copy-to-clipboard__tooltip {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -30px;
    color: #0fb9da;
    animation: fadeOut 1.5s forwards; }
