/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.pagination-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%; }
  .pagination-block__btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 26px;
    height: 26px; }
    .pagination-block__btn div div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px; }
      .pagination-block__btn div div svg {
        width: 12px;
        height: 12px; }
        .pagination-block__btn div div svg g g {
          fill: #9099aa;
          transition: color 0.2s ease; }
    .pagination-block__btn--prev {
      transform: rotate(180deg); }
  .pagination-block .pagination {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 500px; }
    .pagination-block .pagination li {
      width: 28px;
      height: 28px;
      padding: 0;
      border: none;
      border-top: 1px solid #fafafa;
      border-bottom: 1px solid #fafafa;
      background-color: #fafafa;
      text-align: center;
      transition: none;
      list-style: none;
      cursor: pointer; }
      .pagination-block .pagination li a {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        border: none;
        color: grey;
        text-align: center;
        text-decoration: none;
        outline: none;
        transition: none;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500; }
        .pagination-block .pagination li a:after {
          position: absolute;
          z-index: 1;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          display: block;
          width: 24px;
          height: 2px;
          background-color: #262d57;
          opacity: 0; }
        .pagination-block .pagination li a:hover {
          border: none;
          color: #262d57; }
      .pagination-block .pagination li.active {
        pointer-events: none; }
        .pagination-block .pagination li.active a {
          color: #0fb9da; }
          .pagination-block .pagination li.active a:after {
            background-color: #0fb9da;
            opacity: 1; }
      .pagination-block .pagination li.previous {
        position: relative;
        flex-grow: 0;
        border: 1px solid rgba(144, 153, 170, 0.2);
        border-radius: 3px 0 0 3px;
        background-color: #fff; }
        .pagination-block .pagination li.previous a:after {
          display: none; }
        .pagination-block .pagination li.previous a:hover svg g g {
          fill: #262d57; }
      .pagination-block .pagination li.next {
        position: relative;
        flex-grow: 0;
        border: 1px solid rgba(144, 153, 170, 0.2);
        border-radius: 0 3px 3px 0;
        background-color: #fff; }
        .pagination-block .pagination li.next a:after {
          display: none; }
        .pagination-block .pagination li.next a:hover svg g g {
          fill: #000; }
      .pagination-block .pagination li.disabled {
        pointer-events: none; }
        .pagination-block .pagination li.disabled a {
          opacity: 0.3; }
      .pagination-block .pagination li.break-me {
        pointer-events: none; }
        .pagination-block .pagination li.break-me a {
          opacity: 0.5; }
