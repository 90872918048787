.login-form {
  &__buttons-block {
    margin-top: 45px;
  }

  &__links-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    .link:last-child {
      margin-top: 55px;
    }
  }
}
