/**
* Rest styles
*/

@import '../constants/index.scss';

.img-cover {
  max-width: 100%;
  height: auto;
  display: block;

  @supports (object-fit: cover) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.img-contain {
  max-width: 100%;
  height: auto;
  display: block;

  @supports (object-fit: contain) {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.link {
  text-decoration: none;
  transition: opacity 0.3s ease;
  color: $linkColor;

  &--underlined {
    text-decoration: underline;
  }

  &--button-transparent {
    text-transform: uppercase;
    display: inline-block;
    font: 400 14px/16px $mainFont;
    border: 1px solid transparent;
    text-align: center;
  }

  &--transparent {
    font: 400 14px/16px $mainFont;
    color: $white;
    opacity: 0.66;
  }

  &--white {
    color: $white;
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    opacity: 0.8;
  }
}
