/**
* Core styles
*/

@import '_reset.scss';
@import '../constants/index.scss';

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font: 400 14px 'SFPro', Arial, san-serif;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
}

input,
textarea {
  transition: all 0.3s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type='text'] {
  -moz-appearance: textfield;
  &::-ms-clear {
    display: none;
  }
}

input[type='password'] {
  &::-ms-reveal {
    display: none;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']:hover,
input[type='number']:focus {
  -moz-appearance: textfield;
}

input,
textarea,
select {
  outline: none !important;
}

a {
  color: $mainBlue;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  outline: none;
  &:hover,
  &:link,
  &:active,
  &:visited {
    outline: none;
  }
  &:hover {
    text-decoration: none;
  }
}

li {
  list-style: none;
}

button,
input[type='submit'] {
  display: block;
  background: transparent;
  border-radius: 0;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  cursor: pointer;
  &:focus,
  &:active {
    outline: none;
  }
}

svg {
  pointer-events: none;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

@import '_rest.scss';
