/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.table {
  position: relative;
  width: 100%;
  background-color: #fff;
  min-width: 700px; }
  .table__wrapper {
    overflow-x: auto; }
  @media (max-width: 1023px), (orientation: portrait) {
    .table {
      background-color: transparent;
      box-shadow: none; } }
  .table__header {
    position: relative;
    height: 40px;
    width: 100%;
    min-width: 100%;
    padding: 0 24px;
    background-color: #fff;
    border-bottom: 1px solid rgba(144, 153, 170, 0.2); }
  .table__header-list {
    display: flex;
    align-items: center;
    height: 100%; }
  .table__header-list-li {
    padding-right: 6px;
    color: #9099aa;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .table__header-list-li:last-of-type {
      padding-right: 0;
      text-align: end; }
      .table__header-list-li:last-of-type .table__header-list-li-inner {
        justify-content: flex-end; }
  .table__header-list-li-inner {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer; }
    .table__header-list-li-inner span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .table__header-list-li-inner--active {
      color: #262d57;
      font-weight: 500; }
      .table__header-list-li-inner--active .table__sort-icon-wrapper div div svg path {
        fill: #262d57; }
    .table__header-list-li-inner--active-asc .table__sort-icon-wrapper {
      transform: rotate(0.75turn); }
  .table__sort-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 20px;
    height: 16px;
    transform: rotate(0.25turn); }
    .table__sort-icon-wrapper div div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 18px;
      height: 18px; }
      .table__sort-icon-wrapper div div svg {
        width: 18px;
        height: 18px; }
        .table__sort-icon-wrapper div div svg path {
          fill: #9099aa; }
  .table__body {
    width: 100%;
    min-height: 540px; }
    @media (max-width: 1023px), (orientation: portrait) {
      .table__body {
        min-height: initial; } }
  .table__pagination-block {
    margin-top: 20px; }
