/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.page-title {
  width: calc(100% - 280px);
  background-color: #fff;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 400;
  padding: 0 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 80px; }
  .page-title--desktop-hidden {
    display: none; }
  @media (max-width: 1023px) {
    .page-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      padding: 0 15px;
      padding-top: 10px;
      padding-left: 40px;
      flex-wrap: wrap;
      height: auto;
      background-color: #262d57; } }
  .page-title__title {
    color: #262d57;
    max-width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 30px;
    font-weight: 300; }
    @media (max-width: 1023px) {
      .page-title__title {
        font-size: 20px;
        width: 100%;
        text-align: center;
        padding-right: 25px;
        color: #fff; } }
  @media (max-width: 1023px) {
    .page-title--mobile-hidden {
      display: none; }
    .page-title--desktop-hidden {
      display: block; } }
  .page-title__side-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    min-height: 32px; }
    @media (max-width: 1023px) {
      .page-title__side-block {
        width: 100%; }
        .page-title__side-block .button__wrapper {
          max-width: 130px;
          max-height: 28px;
          font-size: 10px;
          margin: 0; } }
    .page-title__side-block > *:not(:last-child) {
      margin-right: 15px; }
