/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.toast-notifications {
  box-sizing: border-box;
  max-height: 100%;
  overflow-x: hidden;
  pointer-events: auto;
  position: fixed;
  z-index: 100;
  bottom: 0px;
  right: 0px;
  padding: 8px;
  max-width: 100%; }
  @media (max-width: 1023px) {
    .toast-notifications {
      max-height: 175px;
      width: 100%;
      bottom: 10px; } }
  .toast-notifications__container {
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse; }
    @media (max-width: 1023px) {
      .toast-notifications__container {
        align-items: center; } }
