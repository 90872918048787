/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.sidebar-link-item {
  width: 100%;
  padding: 17px 10px 17px 28px;
  color: #999caf;
  display: block;
  transition: background 0.3s, color 0.3s;
  text-decoration: none;
  font-size: 15px;
  border: 0;
  border-left: 3px solid transparent; }
  .sidebar-link-item--active, .sidebar-link-item:hover {
    color: #fff; }
    .sidebar-link-item--active .sidebar-link-item__icon-wrapper svg path, .sidebar-link-item:hover .sidebar-link-item__icon-wrapper svg path {
      fill: #fff; }
  .sidebar-link-item--active {
    border-left-color: #02bbf1;
    background: linear-gradient(90deg, rgba(126, 142, 218, 0.13) 0%, rgba(255, 255, 255, 0) 100%); }
    .sidebar-link-item--active .sidebar-link-item__sublinks-block {
      display: block; }
  .sidebar-link-item:hover {
    cursor: pointer; }
  .sidebar-link-item__link-block {
    display: flex;
    align-items: center;
    position: relative; }
  .sidebar-link-item__sublinks-block {
    display: none; }
    .sidebar-link-item__sublinks-block .sidebar-link-item {
      border: 0;
      background: transparent;
      font-size: 13px;
      padding-left: 35px;
      padding-right: 0;
      position: relative; }
      .sidebar-link-item__sublinks-block .sidebar-link-item:first-child {
        margin-top: 17px; }
      .sidebar-link-item__sublinks-block .sidebar-link-item:after, .sidebar-link-item__sublinks-block .sidebar-link-item:before {
        display: block;
        content: '';
        position: absolute;
        background: #999caf;
        transform: translateY(-50%);
        transition: background 0.3s; }
      .sidebar-link-item__sublinks-block .sidebar-link-item:before {
        border-radius: 50%;
        width: 5px;
        height: 5px;
        top: 50%;
        left: 5px; }
      .sidebar-link-item__sublinks-block .sidebar-link-item:after {
        width: 1px;
        height: 47px;
        bottom: 5px;
        left: 7px;
        z-index: -1; }
      .sidebar-link-item__sublinks-block .sidebar-link-item:hover:before, .sidebar-link-item__sublinks-block .sidebar-link-item--active:before {
        background: #fff; }
  .sidebar-link-item__icon-wrapper {
    width: 15px;
    margin-right: 20px; }
    .sidebar-link-item__icon-wrapper div {
      display: flex;
      align-items: center; }
      .sidebar-link-item__icon-wrapper div svg path {
        fill: #999caf;
        transition: fill 0.3s; }
