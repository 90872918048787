/**
* Reset styles
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &:after,
  &:before {
    box-sizing: border-box;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  border: 0;
  display: block;
  max-width: 100%;
}
