/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.loader__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  .loader__wrapper span {
    display: block;
    border-radius: 50%;
    animation-name: blink;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: both; }
  .loader__wrapper span:nth-child(2) {
    animation-delay: 0.1s; }
  .loader__wrapper span:nth-child(3) {
    animation-delay: 0.2s; }
  .loader__wrapper--white span {
    background-color: #fff; }
  .loader__wrapper--mainBlue span {
    background-color: #0fb9da; }
  .loader__wrapper--small span {
    width: 6px;
    height: 6px;
    margin: 2px; }
  .loader__wrapper--normal span {
    width: 8px;
    height: 8px;
    margin: 3px; }
  .loader__wrapper--big span {
    width: 12px;
    height: 12px;
    margin: 4px; }
  .loader__wrapper--stretch {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center; }

@keyframes blink {
  0% {
    opacity: 0.2; }
  20% {
    opacity: 1; }
  100% {
    opacity: 0.2; } }
