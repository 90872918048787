/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.checkbox__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%; }
  .checkbox__wrapper--align-left {
    justify-content: flex-start; }

.checkbox__title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  font: 400 15px/18px "SFPro";
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  .checkbox__title span {
    transition: 0.2s all linear;
    cursor: pointer; }

.checkbox__input {
  position: relative;
  width: 16px;
  height: 16px;
  border: 1px solid #fff;
  cursor: pointer;
  transition: 0.2s all linear;
  outline: none;
  margin-right: 8px; }
  .checkbox__input[disabled],
  .checkbox__input[disabled] ~ span {
    opacity: 0.7; }
  .checkbox__input[disabled]:checked {
    background-color: #9099aa;
    border-color: #9099aa; }
  .checkbox__input:after {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    content: '';
    display: block;
    width: 8px;
    height: 12px;
    border: 3px solid #262d57;
    opacity: 0;
    transition: 0.2s all linear;
    border-top: none;
    border-left: none;
    background-color: transparent;
    border-radius: 3px; }
  .checkbox__input--main-input {
    border-color: #9099aa; }
    .checkbox__input--main-input:after {
      border-color: #fff; }
    .checkbox__input--main-input ~ span {
      color: #262d57; }
  .checkbox__input--error {
    border-color: #fa6678; }
    .checkbox__input--error:checked ~ span {
      color: #fff; }
    .checkbox__input--error ~ span,
    .checkbox__input--error ~ span a {
      color: #fa6678; }
  .checkbox__input:checked {
    border: 1px solid #0fb9da;
    background-color: #0fb9da; }
    .checkbox__input:checked:after, .checkbox__input:checked:before {
      opacity: 1; }
