/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.subscription-plan {
  max-width: 240px;
  border-radius: 6px;
  border: 1px solid #262d57;
  color: #262d57;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column; }
  .subscription-plan--disabled {
    color: #9099aa;
    border-color: #9099aa;
    opacity: 0.7; }
    .subscription-plan--disabled .subscription-plan__price {
      font-size: 30px; }
  .subscription-plan__header {
    text-align: center;
    padding: 25px 10px 0; }
  .subscription-plan__heading {
    font-size: 20px;
    font-weight: bold;
    line-height: 26px; }
  .subscription-plan__description {
    font-size: 12px;
    line-height: 18px;
    min-height: 36px;
    margin-top: 5px; }
  .subscription-plan__price {
    font-size: 40px;
    font-weight: bold;
    line-height: 60px;
    margin-top: 15px; }
  .subscription-plan__vat {
    margin-bottom: 15px;
    min-height: 20px; }
  .subscription-plan__button-block {
    text-align: center;
    padding: 30px 0;
    background-color: rgba(144, 153, 170, 0.2); }
  .subscription-plan__body {
    text-align: center;
    margin-top: 10px;
    flex: 1; }
  .subscription-plan__body-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 17px;
    padding: 5px 10px;
    min-height: 47px; }
    .subscription-plan__body-item:not(:first-child) {
      border-top: 1px solid rgba(144, 153, 170, 0.2); }
