/**
* Config
*/
/**
* Colors
*/
/**
* Animations
*/
@keyframes popup-content {
  0% {
    transform: translateY(20px); }
  100% {
    transform: translateY(0px); } }

@keyframes show {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeUp {
  0% {
    transform: translateY(30px);
    opacity: 0; }
  100% {
    transform: translateY(0px);
    opacity: 1; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/**
* Mixins
*/
.auth-form-structure {
  width: 100%;
  max-width: 700px;
  min-height: 485px;
  margin: 0 auto;
  background-color: rgba(38, 45, 87, 0.9);
  padding: 45px 50px 30px 50px;
  animation: fadeUp 0.4s;
  display: flex;
  flex-direction: column; }
  @media (max-width: 1023px) {
    .auth-form-structure {
      animation: none;
      min-height: 100vh; } }
  .auth-form-structure__body {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    flex: 1; }
  .auth-form-structure__logo {
    max-width: 125px;
    margin: 0 auto; }
  .auth-form-structure--small {
    max-width: 375px; }
