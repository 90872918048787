.youtube-video {
  position: relative;
  padding: 30px 0;
  max-width: 500px;
  margin: 0 auto;

  & > div {
    position: relative;
    padding-top: 56.25%;

    .youtube-video__player {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
