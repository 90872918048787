/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.terms-block--white p {
  color: #fff; }
