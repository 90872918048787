/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.center-block {
  width: 100%;
  margin: 0 auto;
  max-width: 625px; }
  .center-block--with-padding {
    padding-top: 30px; }
  .center-block--with-large-padding {
    padding-top: 100px; }
    @media (max-width: 1023px) {
      .center-block--with-large-padding {
        padding-top: 30px; } }
  .center-block--flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media (max-width: 1023px) {
      .center-block--flex {
        justify-content: center; } }
