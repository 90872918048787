/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.integration-block {
  width: 240px;
  height: 240px;
  border-radius: 3px;
  border: 1px solid rgba(144, 153, 170, 0.2);
  transition: 0.3s;
  padding: 50px 30px 30px;
  margin-bottom: 20px; }
  .integration-block:hover {
    border-color: #0fb9da; }
    .integration-block:hover .integration-block__icon {
      background-color: rgba(15, 185, 218, 0.25);
      border-color: transparent; }
  .integration-block__icon {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    margin: 0 auto;
    border: 1px solid rgba(15, 185, 218, 0.25);
    padding: 15px;
    margin-bottom: 40px;
    transition: 0.3s; }
    .integration-block__icon div {
      width: 33px;
      height: 33px; }
    .integration-block__icon svg path,
    .integration-block__icon svg polygon {
      fill: #0fb9da; }
