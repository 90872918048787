/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.payment-card {
  border-radius: 6px;
  border: 1px solid rgba(144, 153, 170, 0.2);
  padding: 15px 20px 10px;
  max-width: 350px;
  min-height: 220px;
  width: 100%;
  background-color: #fff; }
  @media (max-width: 1023px) {
    .payment-card {
      background-color: transparent; } }
  .payment-card__wrapper {
    position: relative;
    margin-bottom: 80px;
    max-width: 540px;
    margin: 0 auto 80px; }
  .payment-card--front {
    position: relative;
    box-shadow: rgba(144, 153, 170, 0.5) 9px 10px 2px;
    z-index: 1; }
  .payment-card--back {
    padding: 30px 0;
    position: absolute;
    top: 35px;
    right: 0px; }
    @media (max-width: 1023px) {
      .payment-card--back {
        top: 0;
        border: 0;
        left: 0; } }
    .payment-card--back .payment-card__body {
      padding: 30px 20px; }
    .payment-card--back .payment-card__row {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
    .payment-card--back .payment-card__row-label {
      font-size: 12px;
      margin-bottom: 0;
      font-weight: 700;
      margin-right: 10px; }
      @media (max-width: 350px) {
        .payment-card--back .payment-card__row-label {
          margin-right: 5px;
          font-size: 10px; } }
    .payment-card--back .payment-card__row-input {
      width: 70px; }
      @media (max-width: 1023px) {
        .payment-card--back .payment-card__row-input {
          position: relative;
          z-index: 1; } }
  .payment-card__magnet {
    background-color: #9b9b9b;
    height: 50px; }
  .payment-card__footer {
    border-top: 1px solid rgba(144, 153, 170, 0.2);
    display: flex;
    align-items: center;
    padding-top: 10px; }
  .payment-card__footer-image {
    height: 30px;
    align-items: center;
    margin-right: 10px; }
    @media (max-width: 1023px) {
      .payment-card__footer-image {
        margin-right: 0; } }
    .payment-card__footer-image--visa {
      height: 20px; }
    .payment-card__footer-image--mastercard {
      height: 45px; }
    .payment-card__footer-image--mastercard-secure {
      height: 35px; }
    @media (max-width: 350px) {
      .payment-card__footer-image {
        height: 20px;
        margin-right: 5px; } }
  .payment-card__row {
    margin-bottom: 15px; }
    .payment-card__row--short {
      max-width: 125px; }
  .payment-card__row-label {
    text-transform: uppercase;
    font-size: 10px;
    margin-bottom: 5px; }
  .payment-card .StripeElement {
    background-color: #f1f2f4;
    font-size: 18px;
    padding: 10px; }
