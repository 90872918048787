/**
* Config
*/
/**
* Colors
*/
.auth-structure {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(38, 45, 87, 0.9);
  overflow: hidden; }
  .auth-structure__body {
    flex: 1; }
