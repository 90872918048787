/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.datepicker.rdtOpen ~ label {
  top: -7px;
  font-size: 10px;
  cursor: default;
  transform: translateY(0); }

.datepicker.rdtOpen .rdtPicker {
  display: block; }

.datepicker--select-style ~ label {
  text-transform: capitalize;
  left: 16px; }

.datepicker--select-style ~ .datepicker__datepicker-label--filled {
  top: -16px;
  left: 6px; }

.datepicker--select-style.rdtOpen ~ label {
  top: -16px;
  left: 6px; }

.datepicker__wrapper {
  position: relative;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column; }
  .datepicker__wrapper .form-control {
    padding: 0.6rem 1rem;
    border: 0;
    border-bottom: 1px solid rgba(74, 144, 226, 0.2);
    border-radius: 3px;
    display: block;
    transition: border 0.3s;
    width: 100%;
    order: 2;
    color: #262d57;
    appearance: none; }
    .datepicker__wrapper .form-control:hover {
      border-color: #262d57; }
    .datepicker__wrapper .form-control:focus {
      outline: none;
      border-color: #0fb9da; }
  .datepicker__wrapper .rdtPicker {
    background: #fff;
    position: absolute;
    top: 42px;
    padding: 0.75rem;
    z-index: 500;
    border-radius: 5px;
    min-width: 190px;
    display: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 24px; }
    .datepicker__wrapper .rdtPicker table {
      width: 100%; }
      .datepicker__wrapper .rdtPicker table td,
      .datepicker__wrapper .rdtPicker table th {
        padding: 5px 7px;
        text-align: center;
        cursor: pointer;
        border-radius: 3px;
        transition: color 0.3s, background 0.3s; }
        .datepicker__wrapper .rdtPicker table td.rdtToday,
        .datepicker__wrapper .rdtPicker table th.rdtToday {
          background: rgba(74, 144, 226, 0.2);
          color: #0fb9da;
          font-weight: 600; }
        .datepicker__wrapper .rdtPicker table td:hover,
        .datepicker__wrapper .rdtPicker table th:hover {
          background: rgba(74, 144, 226, 0.2); }
        .datepicker__wrapper .rdtPicker table td:active,
        .datepicker__wrapper .rdtPicker table th:active {
          background: rgba(74, 144, 226, 0.2); }
        .datepicker__wrapper .rdtPicker table td.rdtActive,
        .datepicker__wrapper .rdtPicker table th.rdtActive {
          background: #0fb9da;
          color: #fff; }
    .datepicker__wrapper .rdtPicker .rdtTime table td:hover,
    .datepicker__wrapper .rdtPicker .rdtTime table th:hover {
      background: transparent; }
  .datepicker__wrapper .rdtDay[disabled] {
    color: gray;
    pointer-events: none; }
  .datepicker__wrapper .rdtNext,
  .datepicker__wrapper .rdtPrev {
    user-select: none; }
  .datepicker__wrapper .rdtCounters {
    display: flex;
    align-items: center;
    justify-content: center; }
    .datepicker__wrapper .rdtCounters .rdtCounter {
      padding: 0 0.25rem;
      min-width: 50px; }
    .datepicker__wrapper .rdtCounters .rdtBtn {
      border-radius: 3px;
      padding: 5px 10px; }
      .datepicker__wrapper .rdtCounters .rdtBtn:hover {
        background: rgba(74, 144, 226, 0.2); }
      .datepicker__wrapper .rdtCounters .rdtBtn::selection {
        background: transparent; }
  .datepicker__wrapper--disabled:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    content: '';
    display: block;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.2);
    pointer-events: none; }

.datepicker__input {
  border: none;
  width: 100%;
  height: 40px;
  padding: 4px 56px 4px 0;
  border-bottom: 1px solid rgba(74, 144, 226, 0.2);
  font-size: 15px;
  font-weight: 400;
  font-family: "SFPro";
  color: #fff;
  background-color: transparent; }
  .datepicker__input--default-paddings {
    padding: 4px 0; }
  .datepicker__input--main {
    color: #262d57;
    border-color: rgba(144, 153, 170, 0.2); }
  .datepicker__input--select-style {
    border: 1px solid rgba(144, 153, 170, 0.2);
    border-radius: 3px;
    padding-left: 16px; }
    .datepicker__input--select-style:hover {
      border-color: rgba(74, 144, 226, 0.4);
      background-color: rgba(0, 0, 0, 0); }
    .datepicker__input--select-style:focus {
      border-color: #0fb9da;
      background-color: transparent; }
  .datepicker__input::placeholder {
    color: transparent; }
  .datepicker__input::-webkit-inner-spin-button, .datepicker__input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; }
  .datepicker__input:hover {
    border-bottom: 1px solid rgba(74, 144, 226, 0.4);
    background-color: rgba(0, 0, 0, 0); }
  .datepicker__input:focus {
    border-bottom: 1px solid #0fb9da;
    background-color: transparent; }
  .datepicker__input--error {
    border-bottom: 1px solid #fa6678;
    color: #fa6678; }

.datepicker__datepicker-label {
  font-size: 15px;
  position: absolute;
  top: 5px;
  left: 0;
  color: #9099aa;
  transition: 0.2s;
  cursor: text;
  top: 50%;
  transform: translateY(-50%);
  text-transform: uppercase; }
  .datepicker__datepicker-label--error {
    top: -7px;
    font-size: 10px;
    cursor: default;
    transform: translateY(0); }
    .datepicker__datepicker-label--error:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: #fa6678;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      display: block;
      right: -10px; }
  .datepicker__datepicker-label--filled {
    top: -7px;
    font-size: 10px;
    cursor: default;
    transform: translateY(0); }

.datepicker__icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 39px;
  height: 38px;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  pointer-events: none; }
  .datepicker__icon div {
    width: 25px;
    height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
  .datepicker__icon svg path {
    fill: #0fb9da; }
