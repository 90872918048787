/**
* Config
*/
/**
* Colors
*/
.icon-button__wrapper {
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: none;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center; }
  .icon-button__wrapper div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .icon-button__wrapper:hover {
    opacity: 0.8;
    cursor: pointer; }
  .icon-button__wrapper--grey svg path {
    fill: #9099aa; }
  .icon-button__wrapper--mainBlue svg path {
    fill: #0fb9da; }
  .icon-button__wrapper--rounded {
    border-radius: 50%; }
  .icon-button__wrapper--with-background {
    background-color: rgba(74, 144, 226, 0.2); }
  .icon-button__wrapper--small {
    width: 30px;
    height: 30px; }
  .icon-button__wrapper--xsmall {
    width: 17px;
    height: 17px; }
