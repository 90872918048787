/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.textarea__wrapper {
  position: relative;
  width: 100%;
  margin-top: 10px; }
  .textarea__wrapper--disabled:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    content: '';
    display: block;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.2);
    pointer-events: none; }

.textarea__textarea {
  border: none;
  width: 100%;
  height: 40px;
  padding: 4px 56px 4px 0;
  border: 1px solid transparent;
  border-bottom-color: rgba(74, 144, 226, 0.2);
  font-size: 13px;
  font-weight: 400;
  font-family: "SFPro";
  color: #fff;
  background-color: transparent;
  border-radius: 3px;
  padding: 12px 56px 12px 16px;
  resize: none;
  min-height: 80px; }
  .textarea__textarea--default-paddings {
    padding: 4px 0; }
  .textarea__textarea--main {
    color: #262d57;
    border-bottom-color: rgba(144, 153, 170, 0.2); }
    .textarea__textarea--main ~ label {
      color: #9099aa; }
  .textarea__textarea::placeholder {
    color: transparent; }
  .textarea__textarea::-webkit-inner-spin-button, .textarea__textarea::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; }
  .textarea__textarea:hover {
    border-color: transparent;
    border-bottom-color: rgba(74, 144, 226, 0.4);
    background-color: rgba(0, 0, 0, 0); }
  .textarea__textarea:focus, .textarea__textarea:not(:empty) {
    border: 1px solid #0fb9da;
    background-color: rgba(234, 245, 255, 0.3); }
  .textarea__textarea:focus ~ label,
  .textarea__textarea:not(:placeholder-shown) ~ label {
    top: -15px;
    font-size: 10px;
    color: #9099aa;
    cursor: default;
    transform: translateY(0); }
  .textarea__textarea--error {
    border: 1px solid #fa6678;
    color: #fa6678; }
    .textarea__textarea--error ~ label {
      top: -15px;
      font-size: 10px;
      color: #9099aa;
      cursor: default;
      transform: translateY(0); }
      .textarea__textarea--error ~ label:after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: #fa6678;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        display: block;
        right: -10px; }

.textarea__input-label {
  font-size: 15px;
  position: absolute;
  top: 5px;
  left: 0;
  color: #fff;
  transition: 0.2s;
  cursor: text;
  text-transform: uppercase; }
