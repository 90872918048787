.stripe-subscription-form {
  &__upper-block {
    max-width: 310px;
    margin: 0 auto;
  }

  &__buttons-block {
    max-width: 310px;
    margin: 0 auto;
    display: flex;

    button {
      &:first-child {
        margin-right: 15px;
      }
    }
  }
}
