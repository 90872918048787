.faq-form {
  max-width: 335px;
  margin-top: 30px;

  &__input-block {
    margin-top: 40px;
  }

  &__buttons-block {
    display: flex;
    justify-content: flex-end;

    button {
      margin: 0;
      max-width: 110px;
    }
  }
}
