/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.error-404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 12px;
  background-color: #262d57; }
  .error-404__title {
    margin-bottom: 24px;
    font-size: 50px;
    color: #fff; }
  .error-404__icon-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 60%;
    margin-bottom: 100px; }
    .error-404__icon-wrapper div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 125px; }
  .error-404__description {
    margin-bottom: 48px;
    font-size: 20px;
    text-align: center;
    color: #fff; }
  .error-404__back-button {
    width: 100%;
    max-width: 350px; }
