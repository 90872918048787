/**
* Animations
*/
@keyframes popup-content {
  0% {
    transform: translateY(20px); }
  100% {
    transform: translateY(0px); } }

@keyframes show {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeUp {
  0% {
    transform: translateY(30px);
    opacity: 0; }
  100% {
    transform: translateY(0px);
    opacity: 1; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.toast-notification-item {
  background-color: #a3ebf9;
  box-shadow: 0 0 6px 1px rgba(144, 153, 170, 0.2);
  color: #0fb9da;
  display: flex;
  margin-bottom: 8px;
  width: 330px;
  max-width: 100%;
  border-radius: 3px;
  animation: show 0.5s;
  flex-shrink: 0;
  cursor: pointer; }
  .toast-notification-item__status-color {
    background-color: #0fb9da;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    flex-shrink: 0;
    width: 8px; }
  .toast-notification-item__content {
    flex: 1;
    flex-grow: 1;
    font-size: 14px;
    line-height: 1;
    min-height: 50px;
    padding: 8px 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
  .toast-notification-item__close-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-shrink: 0;
    padding: 8px 12px;
    transition: opacity 0.3s;
    font-size: 24px;
    line-height: 1;
    color: #0fb9da; }
    .toast-notification-item__close-button:hover {
      opacity: 0.8; }
  .toast-notification-item--error {
    color: #fa6678;
    background-color: #fee1e5; }
    .toast-notification-item--error .toast-notification-item__status-color {
      background-color: #fa6678; }
    .toast-notification-item--error .toast-notification-item__close-button {
      color: #fa6678; }
  .toast-notification-item--warning {
    color: #f5a623;
    background-color: #fbd79d; }
    .toast-notification-item--warning .toast-notification-item__status-color {
      background-color: #f5a623; }
    .toast-notification-item--warning .toast-notification-item__close-button {
      color: #f5a623; }
  .toast-notification-item--success {
    color: #008e89;
    background-color: #33fff7; }
    .toast-notification-item--success .toast-notification-item__status-color {
      background-color: #00b2ab; }
    .toast-notification-item--success .toast-notification-item__close-button {
      color: #00b2ab; }
