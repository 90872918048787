/**
* Config
*/
/**
* Colors
*/
.heading {
  color: #fff;
  text-align: center;
  font-weight: 300; }
  .heading--black {
    color: #000; }
  .heading--darkBlue {
    color: #262d57; }
  .heading--blue {
    color: #0fb9da; }
  .heading--h1 {
    font-size: 30px;
    line-height: 36px; }
  .heading--h5 {
    font-size: 18px;
    line-height: 21px;
    font-weight: 500; }
  .heading--with-margin {
    margin-bottom: 30px; }
  .heading--capitalize {
    text-transform: uppercase; }
