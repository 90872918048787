/**
* Colors
*/

$white: #fff;
$darkWhite: #fafafa;
$inputWhite: #eaf5ff;
$black: #000;
$mainBlue: #0fb9da;
$lightenBlue: #02bbf1;
$transparentBlue: rgba(15, 185, 218, 0.7);
$darkBlue: #262d57;
$mainRed: #fa6678;
$mainYellow: #f5a623;
$mainGreen: #00b2ab;
$newIconGreen: #15d4a4;
$textGrey: #9099aa;
$darkGrey: #9b9b9b;
$sidebarTextGrey: #999caf;
$transparentGrey: rgba(144, 153, 170, 0.2);
$semiTransparentGrey: rgba(144, 153, 170, 0.5);
$grey: grey;
$lightGrey: #f1f2f4;
$transparentWhite: rgba(255, 255, 255, 0.2);
$transparentBlue: rgba(74, 144, 226, 0.2);

$inputText: $white;
$inputPlaceholder: $textGrey;
$inputBorder: $transparentBlue;
$buttonBackground: $mainBlue;
$linkColor: $mainBlue;
$transparentBlueBackground: rgba(38, 45, 87, 0.9);
