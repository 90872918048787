/**
* Fonts
*/

@font-face {
  font-family: 'SFPro';
  src: url('../../static/fonts/sf-pro-text-light-webfont.woff2') format('woff2'),
    url('../../static/fonts/sf-pro-text-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SFPro';
  src: url('../../static/fonts/sf-pro-text-lightitalic-webfont.woff2') format('woff2'),
    url('../../static/fonts/sf-pro-text-lightitalic-webfont.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'SFPro';
  src: url('../../static/fonts/sf-pro-text-regular-webfont.woff2') format('woff2'),
    url('../../static/fonts/sf-pro-text-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SFPro';
  src: url('../../static/fonts/sf-pro-text-regularitalic-webfont.woff2') format('woff2'),
    url('../../static/fonts/sf-pro-text-regularitalic-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'SFPro';
  src: url('../../static/fonts/sf-pro-text-medium-webfont.woff2') format('woff2'),
    url('../../static/fonts/sf-pro-text-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'SFPro';
  src: url('../../static/fonts/sf-pro-text-mediumitalic-webfont.woff2') format('woff2'),
    url('../../static/fonts/sf-pro-text-mediumitalic-webfont.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'SFPro';
  src: url('../../static/fonts/sf-pro-text-semibold-webfont.woff2') format('woff2'),
    url('../../static/fonts/sf-pro-text-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SFPro';
  src: url('../../static/fonts/sf-pro-text-semibolditalic-webfont.woff2') format('woff2'),
    url('../../static/fonts/sf-pro-text-semibolditalic-webfont.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'SFPro';
  src: url('../../static/fonts/sf-pro-text-bold-webfont.woff2') format('woff2'),
    url('../../static/fonts/sf-pro-text-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SFPro';
  src: url('../../static/fonts/sf-pro-text-bolditalic-webfont.woff2') format('woff2'),
    url('../../static/fonts/sf-pro-text-bolditalic-webfont.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'SFPro';
  src: url('../../static/fonts/sf-pro-text-heavy-webfont.woff2') format('woff2'),
    url('../../static/fonts/sf-pro-text-heavy-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'SFPro';
  src: url('../../static/fonts/sf-pro-text-heavyitalic-webfont.woff2') format('woff2'),
    url('../../static/fonts/sf-pro-text-heavyitalic-webfont.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}
