/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.register-form__upper-block {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 50px;
  grid-auto-flow: column; }
  @media (max-width: 1023px) {
    .register-form__upper-block {
      display: block; } }
  .register-form__upper-block .fullWidth {
    grid-column-start: 1;
    grid-column-end: 3; }

.register-form__buttons-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px; }

.register-form__links-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px; }

.register-form__radio-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 150px; }
