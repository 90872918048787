/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.main-content-structure {
  padding-top: 80px;
  flex-flow: column nowrap;
  flex: 1 1 0%;
  padding-left: 280px;
  max-width: 100vw; }
  @media (max-width: 1023px) {
    .main-content-structure {
      padding-left: 0;
      overflow-y: auto; } }
