/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.ui-field-wrapper__block {
  position: relative;
  width: 100%;
  padding: 0 0 22px;
  margin-bottom: 20px; }
  .ui-field-wrapper__block--textarea .ui-field-wrapper__error {
    top: 85%; }

.ui-field-wrapper__title {
  margin-left: 1px;
  margin-bottom: 2px;
  font: 400 12px/20px "SFPro";
  color: #9099aa;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.ui-field-wrapper__error {
  position: absolute;
  top: 75%;
  padding-bottom: 2px;
  font: 400 12px/14px "SFPro";
  color: #fa6678; }
