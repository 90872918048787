/**
* Config
*/

// -------- UI elements sizes --------

$buttonHeight: 50px;
$mobileButtonHeight: 40px;
$buttonHeightSecondary: 36px;
$fieldHeight: 40px;

// -------- UI elements parameters --------

$desktopBorderRadius: 3px;
$mediumBorderRadius: 6px;
$buttonBorderRadius: 23px;

// -------- Devices sizes --------

$minDesktopSize: 1366px;
$maxMobileSize: 1023px;

// -------- Fonts --------

$mainFont: 'SFPro';
$light: 300;
$regular: 400;
$medium: 500;
$semiBold: 600;
$bold: 700;
$heavy: 800;
