/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.main-structure {
  display: flex;
  min-height: 100vh; }
