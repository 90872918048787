.forgot-password-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 55px;

  &__buttons-block {
    button:last-child {
      margin-top: 10px;
    }
  }
}
