/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.sidebar {
  position: relative;
  min-height: 100vh;
  width: 280px;
  max-width: 280px;
  position: fixed;
  overflow-y: auto;
  left: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 100;
  background-color: #262d57;
  padding-top: 32px; }
  @media (max-width: 1023px) {
    .sidebar {
      z-index: 500;
      transform: translateX(0px);
      transition: transform 0.3s;
      max-height: 100vh; }
      .sidebar--collapsed {
        transform: translateX(-280px);
        overflow-y: visible; }
        .sidebar--collapsed .sidebar__close-button {
          position: fixed;
          top: 8px;
          right: -35px;
          transition: none; }
          .sidebar--collapsed .sidebar__close-button svg path,
          .sidebar--collapsed .sidebar__close-button svg polygon {
            fill: #fff; } }
  .sidebar__logo {
    max-width: 125px;
    margin: 0 auto; }
  .sidebar__menu {
    margin-top: 40px; }
  .sidebar__close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    display: none; }
    .sidebar__close-button svg path,
    .sidebar__close-button svg polygon {
      fill: #fff; }
    @media (max-width: 1023px) {
      .sidebar__close-button {
        display: block; } }
