/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.paragraph {
  font-size: 15px;
  line-height: 1.6;
  font-weight: 300;
  color: #262d57; }
  .paragraph--white {
    color: #fff; }
  .paragraph--red {
    color: #fa6678; }
  .paragraph--small {
    font-size: 12px; }
  .paragraph--medium {
    font-size: 13px; }
  .paragraph--center {
    text-align: center; }
  .paragraph--normal {
    font-weight: 400; }
  .paragraph--medium {
    font-weight: 400; }
  .paragraph--bold {
    font-weight: 600; }
  .paragraph--with-small-margin {
    margin-bottom: 10px; }
  .paragraph--with-margin {
    margin-bottom: 25px; }
  .paragraph--with-large-margin {
    margin-bottom: 50px; }
