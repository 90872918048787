/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.visible-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px; }
  .visible-icon div div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px; }
  .visible-icon svg {
    width: 18px;
    height: 18px; }
    .visible-icon svg path,
    .visible-icon svg circle {
      fill: #fff; }
  .visible-icon--grey svg path,
  .visible-icon--grey svg circle {
    fill: #9099aa; }
