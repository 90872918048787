/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.table-row {
  display: flex;
  height: 54px;
  padding: 0 24px;
  border-bottom: 1px solid rgba(144, 153, 170, 0.2); }
  .table-row__column-cell {
    padding-right: 20px;
    display: flex;
    align-items: center;
    color: #262d57;
    font-size: 13px; }
    .table-row__column-cell:last-child {
      justify-content: flex-end;
      padding: 0; }
    .table-row__column-cell--archive:nth-last-child(-n + 2) {
      justify-content: flex-end;
      padding: 0; }
  .table-row__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 19px;
    max-width: 100%; }
  .table-row__archive-overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 8px 24px;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 70%);
    opacity: 0;
    pointer-events: none;
    transition: background 0.2s ease-in-out; }
    .table-row__archive-overlay--without-permissions {
      background: transparent; }
  .table-row__buttons-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%; }
