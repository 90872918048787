/**
* Config
*/
/**
* Colors
*/
.error-message {
  min-height: 20px;
  color: #fa6678;
  text-align: center;
  transform: translateY(-20px); }
  .error-message--without-transform {
    transform: none; }
  .error-message--with-margin {
    margin-top: 20px; }
