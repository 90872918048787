/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.leads-filter-form {
  display: flex;
  justify-content: space-between;
  max-width: 750px; }
  @media (max-width: 1023px) {
    .leads-filter-form {
      width: 100%;
      flex-wrap: wrap; } }
  .leads-filter-form .ui-field-wrapper__block {
    margin-right: 15px;
    margin-bottom: 0; }
    @media (max-width: 1023px) {
      .leads-filter-form .ui-field-wrapper__block {
        width: 49%;
        margin-right: 0; }
        .leads-filter-form .ui-field-wrapper__block:nth-child(odd) {
          margin-right: 5px; } }
  .leads-filter-form .ui-field-wrapper__block:last-child {
    margin-right: 0; }
