/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.page-wrapper {
  padding: 20px 40px;
  height: 100%; }
  @media (max-width: 1023px) {
    .page-wrapper {
      padding: 15px; } }
