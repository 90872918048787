/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.accounts-row .table-row__column-cell:nth-child(1), .accounts-row .table-row__column-cell:nth-child(2) {
  font-weight: 600; }

.accounts-row--removed .table-row__column-cell {
  color: #9099aa; }
