.facebook-integration-form {
  max-width: 310px;
  margin: 0 auto;

  &__buttons-block {
    display: flex;

    button {
      &:first-child {
        margin-right: 15px;
      }
    }
  }
}
