/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.leads-row__name {
  font-weight: 600;
  max-width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.leads-row--archived .table-row__column-cell {
  color: #9099aa; }

.leads-row__status-icon {
  width: 20px;
  height: 20px; }
  .leads-row__status-icon--new svg path {
    fill: #15d4a4; }
  .leads-row__status-icon--missed svg path {
    fill: #fa6678; }
  .leads-row__status-icon--processed {
    width: 30px;
    height: 30px; }
    .leads-row__status-icon--processed svg path {
      fill: #0fb9da; }
  .leads-row__status-icon--archived svg path {
    fill: #9099aa; }
  .leads-row__status-icon--scheduled svg path {
    fill: #f5a623; }
