/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.empty-content {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
  height: 100%;
  color: rgba(144, 153, 170, 0.5);
  font-size: 18px; }
  .empty-content svg path {
    fill: rgba(144, 153, 170, 0.2); }
  .empty-content__icon {
    max-width: 150px;
    margin: 30px auto 0; }
