/**
* Config
*/
/**
* Colors
*/
/**
* Mixins
*/
.input__wrapper {
  position: relative;
  width: 100%; }
  .input__wrapper--disabled:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    content: '';
    display: block;
    width: 100%;
    height: 40px;
    pointer-events: none; }

.input__input {
  border: none;
  width: 100%;
  height: 40px;
  padding: 4px 56px 4px 0;
  border-bottom: 1px solid rgba(74, 144, 226, 0.2);
  font-size: 15px;
  font-weight: 400;
  font-family: "SFPro";
  color: #fff;
  background-color: transparent; }
  .input__input[disabled] {
    color: #9099aa; }
  .input__input:-webkit-autofill, .input__input:-webkit-autofill:hover, .input__input:-webkit-autofill:focus, .input__input:-webkit-autofill:active {
    transition: none;
    -webkit-box-shadow: 0 0 0 30px #262d57 inset !important;
    -webkit-text-fill-color: #fff;
    caret-color: #fff;
    transition: background-color 9999999s ease 9999999s, box-shadow 9999999s ease 9999999s, border-color 0.3s ease-in-out;
    border-color: rgba(74, 144, 226, 0.2); }
    .input__input:-webkit-autofill:hover, .input__input:-webkit-autofill:hover:hover, .input__input:-webkit-autofill:focus:hover, .input__input:-webkit-autofill:active:hover {
      border-bottom: 1px solid rgba(74, 144, 226, 0.4);
      background-color: rgba(0, 0, 0, 0); }
    .input__input:-webkit-autofill:focus, .input__input:-webkit-autofill:hover:focus, .input__input:-webkit-autofill:focus:focus, .input__input:-webkit-autofill:active:focus {
      border-bottom: 1px solid #0fb9da;
      background-color: transparent; }
  .input__input--default-paddings {
    padding: 4px 0; }
  .input__input--main {
    color: #262d57;
    border-color: rgba(144, 153, 170, 0.2); }
    .input__input--main:-webkit-autofill, .input__input--main:-webkit-autofill:hover, .input__input--main:-webkit-autofill:focus, .input__input--main:-webkit-autofill:active {
      -webkit-text-fill-color: #262d57;
      -webkit-box-shadow: 0 0 0 30px #fff inset !important;
      border-color: rgba(144, 153, 170, 0.2);
      caret-color: #262d57; }
      .input__input--main:-webkit-autofill:hover, .input__input--main:-webkit-autofill:hover:hover, .input__input--main:-webkit-autofill:focus:hover, .input__input--main:-webkit-autofill:active:hover {
        border-bottom: 1px solid rgba(74, 144, 226, 0.4);
        background-color: rgba(0, 0, 0, 0); }
      .input__input--main:-webkit-autofill:focus, .input__input--main:-webkit-autofill:hover:focus, .input__input--main:-webkit-autofill:focus:focus, .input__input--main:-webkit-autofill:active:focus {
        border-bottom: 1px solid #0fb9da;
        background-color: transparent; }
    .input__input--main ~ label {
      color: #9099aa; }
  .input__input::placeholder {
    color: transparent; }
  .input__input::-webkit-inner-spin-button, .input__input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; }
  .input__input:hover {
    border-bottom: 1px solid rgba(74, 144, 226, 0.4);
    background-color: rgba(0, 0, 0, 0); }
    .input__input:hover[disabled] {
      border-color: rgba(144, 153, 170, 0.2); }
  .input__input:focus {
    border-bottom: 1px solid #0fb9da;
    background-color: transparent; }
  .input__input:-webkit-autofill ~ label {
    top: -7px;
    font-size: 10px;
    color: #9099aa;
    cursor: default;
    transform: translateY(0); }
  .input__input:focus ~ label,
  .input__input:not(:placeholder-shown) ~ label {
    top: -7px;
    font-size: 10px;
    color: #9099aa;
    cursor: default;
    transform: translateY(0); }
  .input__input--error {
    border-bottom: 1px solid #fa6678;
    color: #fa6678; }
    .input__input--error:-webkit-autofill, .input__input--error:-webkit-autofill:hover, .input__input--error:-webkit-autofill:focus, .input__input--error:-webkit-autofill:active {
      -webkit-text-fill-color: #fa6678;
      border-bottom: 1px solid #fa6678; }
    .input__input--error ~ label {
      top: -7px;
      font-size: 10px;
      color: #9099aa;
      cursor: default;
      transform: translateY(0); }
      .input__input--error ~ label:after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: #fa6678;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        display: block;
        right: -10px; }

.input__input-label {
  font-size: 15px;
  position: absolute;
  top: 5px;
  left: 0;
  color: #fff;
  transition: 0.2s;
  cursor: text;
  top: 50%;
  transform: translateY(-50%);
  text-transform: uppercase; }
  .input__input-label--uppercase {
    text-transform: uppercase; }

.input__side-block {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1px;
  right: 0;
  width: 40px;
  height: 38px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out; }
  .input__side-block > * {
    cursor: pointer; }
  .input__side-block--auto-width {
    width: auto; }
  .input__side-block--right {
    justify-content: flex-end; }
  .input__side-block--left {
    justify-content: flex-start; }
